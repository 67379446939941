import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import WelcomeOverlay from "../components/home/WelcomeOverlay";
import BackgroundImg from "../components/common/BackgroundImg";


export default (props) => {
  const data = useStaticQuery(graphql`
  query indexImg {
    image: file(relativePath: {eq: "welcomeImg/index.jpg"})  {
      childImageSharp {
        fluid(maxWidth: 3500, maxHeight: 1900, quality: 80, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`);

  return (
    <>
      <BackgroundImg imgFluid={data.image.childImageSharp.fluid} />
      <WelcomeOverlay {...props} />
    </>
  );
}

