import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import CardContainer from "../common/CardContainer";
import WelcomeOverlayHeader from "./WelcomeOverlayHeader";
import styled from "styled-components";
import Colors from "../../styles/Colors";

const WelcomeButton = styled(Button)`
position: relative;
margin: 15px;
text-align: center;
border-color: #004b92;
color: #004b92;
border-width: 0.4em;
font-size: 1.5em;
&:hover {
  color: ${Colors.yellow};
  border-color: ${Colors.yellow};
  transition: all 0.4s ease 0s;
}
`;

export default (props) => {
  const rowStyle = {
    justifyContent: "center"
  }

  const containerStyle = {
    paddingTop: "10vh",
    paddingBottom: "10vh",
    inset: "unset",
  }

  const buttonClassName = "bg-transparent";

  return (
    <CardContainer style={containerStyle}>
      <WelcomeOverlayHeader siteMetadata={props.siteMetadata} />
      <Row style={rowStyle}>
        {props.siteMetadata.menuLinks.map((link, index) => {
          if (link.link === "/") {
            return null;
          } else {
            return (<WelcomeButton onClick={() => props.handleSelectPage(link.link)} className={buttonClassName} key={link.name + index}>{link.name}</WelcomeButton>);
          }
        })}
      </Row>
    </CardContainer>
  );
}