import React from "react";
import Container from 'react-bootstrap/Container';

export default function (props) {
  const containerStyle = {
    position: "relative",
    margin: "auto",
    inset: "10% 0 0 0",
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    ...props.style,
  };
  return (
    <Container style={containerStyle}>
      {props.children}
    </Container>
  );
}