import React from "react";
import Colors from "../../styles/Colors";

export default ({ siteMetadata }) => {
  const containerStyle = {
    textAlign: "center",
    borderColor: Colors.yellow
  }

  const titleStyle = {
    color: Colors.blue,
    marginBottom: "1em"
  }

  const subTitleStyle = {
    marginBottom: "1.5em",
    textAlign: "justify"
  }

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>{siteMetadata.title}</h1>
      {/* <h4 style={subTitleStyle}>{props.siteMetadata.subtitle}</h4> */}
      <h5 style={subTitleStyle}>{siteMetadata.description}</h5>
    </div>
  );
}