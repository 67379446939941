import React from "react";
import Img from "gatsby-image";


export default function ({ imgFluid }) {
  const imgStyle = {
    position: "fixed",
    background: "no-repeat fixed",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    zIndex: "-150",
  };

  const imgContainerStyle = {
    position: "relative",
    height: "fit-content",
  };

  return (
    <div style={imgContainerStyle}>
      <Img fluid={imgFluid} durationFadeIn={1500} style={imgStyle} />
    </div>
  );
}